<template>
  <div class="container-fluid">
    <b-row
      :class="innerWidth ? 'mt-1' : 'mt-0'"
    >
      <b-col
        :cols="innerWidth ? '8' : '10'"
        align="left">
        <b-row>
          <b-col
            :cols="innerWidth ? '' : '12'"
          >
            <div
              :class="bookingNameClass"
              class="mt-2"
            >
              {{ booking.name }}
            </div>
          </b-col>
        </b-row>

        <b-row
          v-if="isFormulaType"
          :class="innerWidth ? 'mt-1' : 'mt-0'"
        >
          <b-col
            :cols="innerWidth ? '' : '12'"
          >
            <div class="d-inline-block">
              <d-button
                :class="innerWidth ? 'd-btn-primary-new d-btn-sm-icon custom-left-borders font-text-title btn-custom-size' : 'icon-style'"
                icon="fa fa-bell-o ml-2-3"
              />
              <label
                class="duration-label"
                :class="innerWidth ? 'ml-2' : 'ml-0'"
              >
                {{ timetableBlockPrice.name }}
              </label>
            </div>
          </b-col>
        </b-row>

        <b-row
          :class="rowDurationClass"
        >
          <b-col :cols=" innerWidth ? '12' : '12'">
            <b-row>
              <b-col
                cols="4"
                :class="innerWidth ? '' : 'pl-1 pr-1'"
              >
                <div class="d-inline-block">
                  <d-button
                    :class="innerWidth ? 'd-btn-primary-new d-btn-sm-icon custom-left-borders font-text-title btn-custom-size' : 'icon-style'"
                    icon="fa fa-clock-o ml-2-3"
                  />
                  <label
                    class="duration-label"
                    :class="innerWidth ? 'ml-2' : 'ml-0'"
                  >
                    {{ duration }} {{ durationTime }}
                  </label>
                </div>
              </b-col>
              <b-col
                v-if="!isFormulaType"
                :class="innerWidth ? 'pl-0 pr-1' : 'pl-0 pr-0'"
                cols="4"
              >
                <div class="d-inline-block">
                  <d-button
                    :class="innerWidth ? 'd-btn-primary-new d-btn-sm-icon custom-left-borders font-text-title btn-custom-size' : 'icon-style'"
                    :icon="innerWidth ? 'fa fa-user ml-2-3' : 'fa fa-user ml-2-3 fa-sm'"
                  />
                  <label
                    class="duration-label"
                    :class="innerWidth ? 'ml-2' : 'ml-0'"
                  >
                    {{ participantsLabel }}
                  </label>
                </div>
              </b-col>
              <b-col
                v-if="hasCoaches"
                :class="innerWidth ? 'pl-0 pr-0' : 'pl-1 pr-1 overflow-name'"
                cols="4"
              >
                <div
                  v-if="innerWidth"
                  class="d-inline-block"
                >
                  <d-button
                    class="d-btn-primary-new d-btn-sm-icon custom-left-borders font-text-title btn-custom-size-sm"
                    icon="icofont icofont-waiter ml-2-3"
                  />
                  <label class="duration-label ml-2">
                    {{ coaches }}
                  </label>
                </div>
                <div
                  v-else
                  class="overflow-name duration-label"
                >
                  <d-button
                    class="icon-style"
                    icon="icofont icofont-waiter ml-2-3"
                  />
                  {{ coaches }}
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        class="pr-0"
        :class="innerWidth ? 'mt-1 pr-0' : 'pr-3 header-margin'"
        :cols="innerWidth ? '' : '2'"
        align="right"
      >
        <b-row>
          <b-col
            v-if="innerWidth"
            cols="12"
          >
            <d-dropdown-form
              :key="'d-drop-down' + dropDownKey"
              :show="show"
              :right="true"
              :identifier="'identifier-'"
              :min-width="customWidth"
              padding="0px"
            >
              <template v-slot:dropdownbutton>
                <d-button
                  v-if="!active"
                  :text="status"
                  :class="statusClass"
                  :translated-text="true"
                  class="d-btn-sm font-text-title btn font-text-title custom-btn-width"
                  icon="fa fa-angle-down mr-1"
                  icon-position="right"
                  @on:button-click="show=!show"
                />
              </template>
              <template v-slot:dropdownbody>
                <a
                  v-if="status !== $t('components.custom.planning.booking-details-modal.general-information.table.paid')"
                  class="dropdown-item"
                  href="#"
                >
                  <d-button
                    :text="$t('components.custom.planning.booking-details-modal.general-information.table.paid')"
                    :translated-text="true"
                    class="d-btn-success d-btn-sm font-text-title btn custom-btn-width"
                    @on:button-click="putBookingPaymentStatus('paid')"
                  />
                </a>
                <a
                  v-else
                  class="dropdown-item"
                  href="#"
                >
                  <d-button
                    :text="$t('components.custom.planning.booking-details-modal.general-information.table.not-paid')"
                    :translated-text="true"
                    class="d-btn-danger d-btn-sm font-text-title btn custom-btn-width"
                    @on:button-click="putBookingPaymentStatus('not-paid')"
                  />
                </a>
              </template>
            </d-dropdown-form>
          </b-col>
          <b-col v-else>
            <d-button
              v-if="!active"
              :class="statusClass"
              class="mobile-button-pay custom-btn-width"
              icon="icofont icofont-cur-euro"
              icon-position="center"
              @on:button-click="putBookingPaymentStatus(statusClass)"
            />
          </b-col>
          <b-col
            v-if="innerWidth"
            cols="12"
          >
            <div class="book-at mt-1">
              {{ $t('booking_type.booking-at') + ' ' + createdAt }}
            </div>
          </b-col>
          <b-col
            v-if="innerWidth"
            cols="12"
            :class="innerWidth ? '' : 'mt-2'"
          >
            <div class="booking-status">
              {{ bookingFrom }}
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      v-if="hasComment"
      class="mt-2"
    >
      <b-col
        cols="12"
        :class="innerWidth ? '': 'pl-2'"
      >
        <div class="d-flex">
          <d-button
            :class="innerWidth ? 'd-btn-red-grey d-btn-sm-icon custom-left-borders font-text-title btn-custom-size' : 'icon-style-red'"
            :icon="innerWidth ? 'fa fa-file-text-o ml-2-3 mr-1 mt-1' : 'fa fa-file-text-o fa-sm'"
          />
          <label
            class="description-label"
            :class="innerWidth ? 'ml-2 pt-2 ' : 'mb-0 pt-1 ml-2'"
          >
            {{ booking.comment }}
          </label>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {hydrate} from "@/utils/form";
import {LEISURE_TYPE, LESSON_TYPE} from "@/classes/doinsport/Price";
import {DAY_SLASH_MONTH_SLASH_YEAR, MINUTES} from "@/utils/date";
import {diffUtc, toTimezoneCustomFormat} from "@/utils/timezone";
import {updateBooking} from "@api/doinsport/services/bookings/booking.api";
import {FORMULA_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  data: () => ({
    dropDownKey: 0,
    show: false,
  }),
  props: {
    booking: {
      type: Object,
      default: () => {
      },
    },
    timetableBlockPrice: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    isFormulaType() {
      if (isNotUndefinedAndNotNull(this.timetableBlockPrice.activityType)) {
        return this.timetableBlockPrice.activityType === FORMULA_BOOKING_TYPE;
      }

      return false;
    },
    bookingNameClass() {
      if (this.booking.name.length > 45) {
        return 'participants-label-max-content';
      } else {
        if (this.booking.activityType !== FORMULA_BOOKING_TYPE) {
          return 'participants-label-min-content mb-2';
        } else {
          return 'participants-label-formula-content mb-2';
        }
      }
    },
    rowDurationClass() {
      return this.innerWidth ? this.booking.activityType === FORMULA_BOOKING_TYPE ? 'mt-2' : 'mt-4' : 'mt-0';
    },
    durationTime() {
      if (this.innerWidth) {
        return this.$t('minutes');
      } else {
        return this.$t('min.');
      }
    },
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 700;
    },
    hasCoaches() {
      return (this.booking.activityType === LESSON_TYPE && this.booking.coaches && this.booking.coaches.length > 0);
    },
    coaches() {
      let coachesLabel = '';

      if (this.booking.coaches.length > 1) {
        for (const i in this.booking.coaches) {
          if (parseInt(i) === 0) {
            coachesLabel = coachesLabel + this.booking.coaches[i].firstName;
          } else {
            coachesLabel = coachesLabel + '/' + this.booking.coaches[i].firstName;
          }
        }
      } else {
        coachesLabel = this.booking.coaches[0].firstName + ' ' + this.booking.coaches[0].lastName;
      }

      return coachesLabel;
    },
    participantsLabel() {
      let textParticipants = null;
      let textPlayers = null;

      if (this.innerWidth) {
        textParticipants = this.$t('views.playground.steps.step-3.participants');
        textPlayers = this.$t('views.playground.steps.step-3.players');
      } else {
        textParticipants = this.$t('views.playground.steps.step-3.participants-short');
        textPlayers = this.$t('views.playground.steps.step-3.participants-short');
      }

      if (this.booking.activityType === LESSON_TYPE || this.booking.activityType === LEISURE_TYPE) {
        return this.booking.activityType === LEISURE_TYPE ? this.booking.participantsCount + ' ' + textParticipants : this.booking.participantsCount + '/' + this.booking.maxParticipantsCountLimit + ' ' + textParticipants;
      } else {
        return this.participantsCount + ' ' + textPlayers;
      }
    },
    bookingFrom() {
      return this.booking.creationOrigin === 'administration' ? this.$t('booking_type.booking-from-club') : this.$t('booking_type.booking-from-app');
    },
    hasComment() {
      if (null === this.booking.comment) {
        return false;
      } else {
        if (this.booking.comment.trim().length === 0) {
          return false;
        }
      }

      return true;
    },
    participantsCount() {
      return this.timetableBlockPrice.maxParticipantsCountLimit;
    },
    duration() {
      return diffUtc(this.booking.endAt, this.booking.startAt, MINUTES);
    },
    createdAt() {
      return toTimezoneCustomFormat(this.booking.createdAt, DAY_SLASH_MONTH_SLASH_YEAR);
    },
    customWidth() {
      return '143px'
    },
    status() {
      if (this.booking.restToPay <= 0 || this.booking.consideredAsPaidAt !== null) {
        return this.$t('components.custom.planning.booking-details-modal.general-information.table.paid');
      }

      if (this.booking.restToPay <= this.booking.price) {
        return this.$t('components.custom.planning.booking-details-modal.general-information.table.not-paid');
      }
      return this.$t('components.custom.planning.booking-details-modal.general-information.table.pending');
    },
    statusClass() {
      if (this.booking.restToPay <= 0 || this.booking.consideredAsPaidAt !== null) {
        return 'd-btn-success';
      }
      if (this.booking.restToPay <= this.booking.price) {
        return 'd-btn-danger';
      }
      if (this.booking.restToPay < this.booking.price) {
        return 'd-btn-warning';
      }
    },
  },
  methods: {
    putBookingPaymentStatus(status) {
      let paymentData = null;

      if (status === 'paid' || status === 'd-btn-danger') {
        paymentData = this.$moment.utc().tz(this.$store.getters["auth/currentClub"].timezone);
      }

      updateBooking(this.booking.id, {consideredAsPaidAt: paymentData})
        .then((response) => {
          hydrate(this.booking, response.data);
        })
        .finally(() => {
          this.dropDownKey++;
        })
      ;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/bookings/participants/_participants.scss";

/deep/ .custom-btn-width.d-btn-danger {
  display: flex;
  align-items: center;
  padding: 0.375rem 1.75rem;
}

/deep/ .btn.custom-btn-width.d-btn-warning {
  padding-left: 0.375rem;
  padding-right: 0.1rem;
}

</style>
